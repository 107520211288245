<template>
  <v-card v-if="atividade">
    <v-card-title class="text-subtitle-2 font-weight-bold">
      TOTAL ATIVIDADE
      <v-spacer></v-spacer>
      {{ atividade.total }}
    </v-card-title>

    <v-expansion-panels flat hover accordion>
      <v-expansion-panel
        v-for="(colaborador, index) in atividade.colaboradores"
        :key="index"
        class=""
      >
        <v-expansion-panel-header class="px-3 ">
          <v-sheet class="d-flex align-center justify-space-between">
            <v-avatar size="32" v-if="colaborador.avatar">
              <v-img :src="colaborador.avatar" contain></v-img>
            </v-avatar>
            <v-icon v-else size="32">mdi-account-circle</v-icon>
            <span class="ml-3">{{ colaborador.colaborador }}</span>
            <span class=" ml-6">{{ colaborador.total }}</span>
          </v-sheet>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0">
          <v-divider></v-divider>
          <v-card flat class="scrollbar">
            <v-row
              class=""
              v-for="(item, index2) in colaborador.historico"
              :key="index2"
              no-gutters
              align="center"
              style="background-color: #F5F5F5; margin-bottom: 2px;"
            >
              <v-col class="pl-3 font-weight-bold text-caption">
                <v-icon></v-icon>
                {{ item.total }}
              </v-col>
              <v-col class="text-caption font-weight-bold">
                {{ item.created_at | dateFormat("dd/MM/yyyy") }}
              </v-col>
              <v-col cols="auto" class="pr-3">
                <v-menu
                  :close-on-content-click="false"
                  bottom
                  min-width="200px"
                  rounded
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-btn :color="item.note ? 'secondary' : ''" icon v-on="on">
                      <v-icon small>mdi-message-bulleted</v-icon>
                    </v-btn>
                  </template>
                  <v-card class="pa-0">
                    <v-card-title class="accent text-h5 font-weight-bold pa-3">
                      Adicionar Note
                    </v-card-title>
                    <v-card-text>
                      <v-textarea v-model="item.note" :rows="3"></v-textarea>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn small color="primary" @click="updateNote(item)">
                        salvar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
  <v-card v-else>
    Sem Historico
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { putNote } from "@/api/colaborador/stopwatch.js";
export default {
  name: "StopWatchHistorico",

  props: {
    atividade: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      historico: false,
      menu: false,
      historicos: {},
    };
  },

  methods: {
    ...mapActions("Stopwatch", {
      STORE_getHistorico: "getHistorico",
      STORE_check: "check",
    }),

    updateNote(item) {
      this.historicos = { ...item };

      this.loading = true;
      let body = {};
      body.id = this.historicos.id;
      body.note = this.historicos.note;

      putNote(body.id, body)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success("Nota salva!");
            this.historicos = {};
            this.STORE_getHistorico();
            this.loading = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  created() {},
};
</script>

<style lang="scss" scoped>
.scrollbar {
  height: 250px;
  overflow-y: auto;
}

::v-deep .v-expansion-panel .v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>
